import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './style.scss'

class Main extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element
    ])
  }

  render() {
    return (
      <main className="Main" style={this.props.style}>
        {this.props.children}
      </main>
    )
  }
}

export default Main
