import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Intent,
  AnchorButton
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { Initial } from '../constants'
import { hashList } from '../helpers'
import AppContext from '../AppContext'
import List from './List'
import TransactionForm from './Form'

import './style.scss'

class Transactions extends PureComponent {
  static contextType = AppContext
  static propTypes = {
    item: PropTypes.object.isRequired
  }
  state = {
    transaction: Initial.TRANSACTION
  }

  handleAddTransaction() {
    const { action, constants } = this.context
    const { TRANSACTION_TYPES } = constants
    const types = hashList(TRANSACTION_TYPES)

    this.setState(state => ({
      transaction: {
        ...state.transaction,
        type: state.transaction.type || types[0]
      }
    }), () => {
      action.set('dialog', {
        isOpen: true,
        title: 'Add Transaction',
        icon: IconNames.SERIES_ADD,
        style: {
          width: '60rem'
        },
        onClose: () => action.reset('dialog'),
        children: <TransactionForm mode="Add" item={this.state.transaction} parentItem={this.props.item} />
      })
    })
  }

  renderList() {
    const { item } = this.props
    const { id, type } = item
    const { transactions } = this.context
    const items = [item, ...transactions.filter(item => item.assetId === id)]

    return (
      <List items={items} type={type} />
    )
  }

  render() {
    return (
      <div className="Container Transactions">
        <header>
          <AnchorButton
            large
            text="Add Transaction"
            icon={IconNames.SERIES_ADD}
            intent={Intent.PRIMARY}
            onClick={() => this.handleAddTransaction()}
          />
        </header>
        <main>
          {this.renderList()}
        </main>
      </div>
    )
  }
}

export default Transactions
