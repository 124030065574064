import { Position } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import page from 'page'

import { flattenByProp } from './helpers'
import Header from './Header'
import Main from './Main'
import Menu from './Menu'
import Footer from './Footer'
import { Visibility } from './Menu/constants'

const CONSTANTS = {
  ASSET_TYPES: {
    CASH: 'cash',
    PROPERTY: 'property',
    STOCK: 'stock',
    VEHICLE: 'vehicle'
  },
  TRANSACTION_TYPES: {
    DEPOSIT: 'deposit',
    WITHDRAWAL: 'withdrawal'
  }
}

export const Constants = Object.freeze({
  ...CONSTANTS,
  DEBITS: [
    CONSTANTS.TRANSACTION_TYPES.DEPOSIT
  ],
  CREDITS: [
    CONSTANTS.TRANSACTION_TYPES.WITHDRAWAL
  ]
})

export const Component = {
  HEADER: { component: Header },
  MAIN: { component: Main },
  MENU: {
    component: Menu,
    style: Visibility.CLOSED,
    state: {
      isOpen: false,
      isOver: false
    }
  },
  FOOTER: { component: Footer }
}

Component.MENU.state.isOpen = Component.MENU.style === Visibility.OPENED

export const Structure = Object.freeze({
  DEFAULT: [
    Component.HEADER,
    { ...Component.MAIN, children: [Component.MENU] },
    // Component.FOOTER
  ],
  AUTHENTICATED: [
    Component.HEADER,
    { ...Component.MAIN, children: [Component.MENU] },
    // Component.FOOTER
  ]
})

export const MenuItem = Object.freeze({
  DASHBOARD: {
    title: 'Dashboard',
    pathname: '/dashboard',
    onClick: () => {
      page(MenuItem.DASHBOARD.pathname)
    },
    button: {
      icon: IconNames.DASHBOARD
    },
    requiredContext: 'user'
  },
  PORTFOLIO: {
    title: 'Portfolio',
    pathname: '/portfolio',
    onClick: () => {
      page(MenuItem.PORTFOLIO.pathname)
    },
    button: {
      icon: IconNames.FOLDER_OPEN
    }
  },
  SETTINGS: {
    title: 'Settings',
    pathname: '/settings',
    onClick: () => {
      page(MenuItem.SETTINGS.pathname)
    },
    button: {
      icon: IconNames.SETTINGS
    },
    requiredContext: 'user'
  }
})

export const Initial = Object.freeze({
  STRUCTURE: Structure.DEFAULT,
  LAYOUT: flattenByProp('children', Structure.DEFAULT),
  MENU: [
    MenuItem.DASHBOARD,
    MenuItem.PORTFOLIO,
    MenuItem.SETTINGS
  ],
  NOTIFICATION: {
    toasts: [],
    toaster: {
      position: Position.TOP
    }
  },
  DIALOG: {
    isOpen: false
  },
  ALERT: {
    isOpen: false
  },
  USER: null,
  HISTORY: [],
  CONSTANTS: Constants,
  ASSET: {
    type: null,
    name: null,
    cost: 0.0,
    value: 0.0,
    units: 0.0,
    percent: 0.0,
    loan: 0.0
  },
  ASSETS: [],
  TRANSACTION: {
    assetId: null,
    type: null,
    date: new Date(),
    cost: 0.0,
    value: 0.0,
    units: 0.0
  },
  TRANSACTIONS: []
})
