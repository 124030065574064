import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AnchorButton, Icon, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import AppContext from '../AppContext'
import { d, $ } from '../formats'
import { hashList, sortByDate as byDate } from '../helpers'
import './style.scss'

class List extends Component {
  static contextType = AppContext
  static propTypes = {
    items: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired
  }

  handleTransactionDelete(item) {
    const { action } = this.context
    const { id, date } = item

    action.set('alert', {
      isOpen: true,
      icon: IconNames.DELETE,
      intent: Intent.DANGER,
      cancelButtonText: 'Cancel',
      canEscapeKeyCancel: true,
      canOutsideClickCancel: true,
      onClose: () => action.reset('alert'),
      onConfirm: () => {
        action.remove('transactions', id, () => {
          action.addToast({
            icon: IconNames.TICK,
            intent: Intent.SUCCESS,
            message: `${d(date)} deleted`
          })
        })
      },
      children: (
        <p>Are you sure you want to delete <strong>{d(date)}</strong>?</p>
      )
    })
  }

  render() {
    const { constants } = this.context
    const { ASSET_TYPES } = constants
    const { items } = this.props
    const assetTypes = hashList(ASSET_TYPES);
    let _valueSum = 0.0

    if (!items || !items.length) {
      return null
    }

    return (
      <ol>
        {items.sort(byDate).map(item => {
          const { value } = item
          _valueSum += value

          return {
            ...item,
            _valueSum
          }
        }).reverse().map((item, index) => {
          const { type, date, value, _valueSum } = item
          const isTransaction = !assetTypes.includes(type)

          return (
            <li key={index} className="Row">
              <div>
                <h3>
                  {isTransaction && (
                    <>
                      <AnchorButton minimal onClick={() => this.handleTransactionDelete(item)}>
                        <Icon icon={IconNames.DELETE} iconSize={16} />
                      </AnchorButton>
                      {d(date)} &mdash; {type}
                    </>
                  )}
                  {!isTransaction && (
                    <>
                      --
                    </>
                  )}
                </h3>
              </div>
              <div>
                <span>{$(value)}</span>
                <span>{$(_valueSum)}</span>
              </div>
            </li>
          )
        })}
      </ol>
    )
  }
}

export default List
