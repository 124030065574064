import React from 'react'
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { formatNumber } from '../formats';
import Tag from './Tag'
import Content from './Content'

export { Tag, Content }

export function Percentage({ value, text }) {
  if (!value) {
    return null
  }
  return (
    <Tag tagName="sup">
      {formatNumber(value)} {text}
    </Tag>
  )
}

export function Change({ tagName, value, text }) {
  const changeIcon = !!value
  ? value > 0.0
    ? <Icon icon={IconNames.CARET_UP} />
    : <Icon icon={IconNames.CARET_DOWN} />
  : null

  return (
    <Tag tagName={tagName || 'p'}>
      {changeIcon} {text}
    </Tag>
  )
}
