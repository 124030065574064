import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import page from 'page'
import {
  FormGroup,
  InputGroup,
  ControlGroup,
  Button,
  Intent
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import Content from '../Partials/Content'
import { Consumer } from '../AppContext'
import { Structure } from '../constants'

class Login extends PureComponent {
  static propTypes = {
    style: PropTypes.object
  }
  state = {
    user: {
      email: null,
      password: null
    }
  }

  handleEmailChange({ target }) {
    const { value } = target
    this.setState({
      user: {
        ...this.state.user,
        email: value
      }
    })
  }

  handlePasswordChange({ target }) {
    const { value } = target
    this.setState({
      user: {
        ...this.state.user,
        password: value
      }
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    const { action } = this.appContext
    const { email } = this.state.user
    const history = action.get('history')
    const initRoute = history[0] || {}
    const { canonicalPath } = initRoute

    action.set('user', { email }, () => {
      action.setStructure(Structure.AUTHENTICATED, () => {
        page.redirect(canonicalPath || '/')
      })
    })
    action.addToast({
      icon: IconNames.TICK,
      intent: Intent.SUCCESS,
      message: `${email} logged in`
    })
  }

  render() {
    return (
      <Content style={this.props.style}>
        <h1>Login</h1>
        <Consumer>
          {context => {
            this.appContext = context
            return (
              <form onSubmit={event => this.handleSubmit(event)}>
                <FormGroup>
                  <InputGroup
                    autoFocus
                    type="email"
                    placeholder="Email"
                    onChange={event => this.handleEmailChange(event)}
                  />
                </FormGroup>
                <FormGroup>
                  <InputGroup
                    type="password"
                    placeholder="Password"
                    onChange={event => this.handlePasswordChange(event)}
                  />
                </FormGroup>
                <ControlGroup>
                  <Button type="submit" text="Login" />
                </ControlGroup>
              </form>
            )
          }}
        </Consumer>
      </Content>
    )
  }
}

export default Login
