import React from 'react'

import { user, assets, transactions } from './env'
import { Initial, Structure } from './constants'
import { flattenByProp } from './helpers'

const isUser = !!user
const hasAssets = !!assets
const hasTransactions = !!transactions

const AppContext = React.createContext({
  structure: isUser 
    ? Structure.AUTHENTICATED 
    : Initial.STRUCTURE,
  layout: isUser 
    ? flattenByProp('children', Structure.AUTHENTICATED) 
    : Initial.LAYOUT,
  menu: Initial.MENU,
  notification: Initial.NOTIFICATION,
  dialog: Initial.DIALOG,
  alert: Initial.ALERT,
  user: isUser
    ? user
    : Initial.USER,
  history: Initial.HISTORY,
  constants: Initial.CONSTANTS,
  assets: hasAssets
    ? assets
    : Initial.ASSETS,
  transactions: hasTransactions
    ? transactions
    : Initial.TRANSACTIONS
})

export const { Provider, Consumer } = AppContext
export default AppContext