import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Content from '../Partials/Content'
import './style.scss'

class Footer extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element
    ])
  }

  render() {
    return (
      <Content className="Footer" style={this.props.style}>
        <footer>
          &copy; Grit
        </footer>
      </Content>
    )
  }
}

export default Footer
