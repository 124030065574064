import React from 'react'

const Tag = ({ tagName, children, ...rest }) => {
  const TagName = tagName || 'div'
  return (
    <TagName {...rest}>
      {children}
    </TagName>
  )
}

export default Tag