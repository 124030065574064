import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Content from '../Partials/Content'

class NotFound extends PureComponent {
  static propTypes = {
    path: PropTypes.string,
    style: PropTypes.object
  }
  static defaultProps = {
    path: '/'
  }

  render() {
    return (
      <Content style={this.props.style}>
        <p>The requested URL <em>{this.props.path}</em> was not found.</p>
      </Content>
    )
  }
}

export default NotFound
