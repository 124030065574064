import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import AppContext from '../AppContext'
import './style.scss'

class Layout extends PureComponent {
  static contextType = AppContext
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element
    ]),
    extend: PropTypes.string
  }

  renderItem(item) {
    const layoutItem = this.context.layout.find(({ component }) => component === item.component)
    const { key } = item
    const { component, children, ...rest } = layoutItem
    const { name } = component
    const Component = component
    const props = { key, ...rest }
    let components = (children || []).map((item, i) => (
      this.renderItem({ key: i, ...item })
    ))

    if (this.props.extend === name && this.props.children) {
      components = React.isValidElement(this.props.children)
        ? [...components.concat(this.props.children)]
        : [...components, ...this.props.children]
    }

    return (
      <Component {...props}>
        {components}
      </Component>
    )
  }

  render() {
    return (
      <div className="Layout">
        {this.context.structure.map((item, i) => (
          this.renderItem({ key: i, ...item })
        ))}
      </div>
    )
  }
}

export default Layout
