import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import page from 'page'
import {
  Alignment,
  Button,
  AnchorButton,
  Navbar,
  Intent,
  Popover
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { Consumer } from '../AppContext'
import { Structure } from '../constants'
import Login from '../Sessions/Login'
import Menu from '../Menu'
import { Visibility } from '../Menu/constants'

import './style.scss'

class Header extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element
    ])
  }

  handleMenuToggle() {
    const { action, layout } = this.context
    const index = layout.findIndex(item => item.component === Menu)
    const component = layout[index]
    const { isOpen } = component.state
    const state = {
      ...component.state,
      isOpen: !isOpen
    }
    const style = state.isOpen
      ? Visibility.OPENED
      : Visibility.CLOSED

    action.updateAt('layout', { state, style }, index)
  }

  handleSessionLogout() {
    const { action, user } = this.context
    const { email } = user

    action.reset('user', () => {
      action.setStructure(Structure.DEFAULT, () => {
        action.reset('history')
        page('/')
      })
    })

    action.addToast({
      icon: IconNames.TICK,
      intent: Intent.SUCCESS,
      message: `${email} logged out`
    })
  }

  renderMenu() {
    const { action, layout, menu } = this.context

    if (!menu || !menu.length) {
      return
    }

    const index = layout.findIndex(item => item.component === Menu)

    if (index === -1) {
      return
    }

    const component = layout[index]
    const { isOpen, isOver } = component.state
    const state = {
      ...component.state,
      isOver: false
    }
    let icon
    if (isOver) {
      icon = isOpen ? IconNames.MENU_CLOSED : IconNames.MENU_OPEN
    } else {
      icon = IconNames.MENU
    }
    const props = {
      icon,
      onClick: () => this.handleMenuToggle(this.context),
      onMouseEnter: () => action.updateAt('layout', {
        state: { ...state, isOver: true }
      }, index),
      onMouseLeave: () => action.updateAt('layout', {
        state: { ...state, isOver: false }
      }, index)
    }

    return (
      <>
        <Button minimal {...props} />
        <Navbar.Divider />
      </>
    )
  }

  renderSession() {
    const { user } = this.context

    if (!user) {
      return (
        <Popover>
          <Button minimal icon={IconNames.LOG_IN} />
          <Login />
        </Popover>
      )
    }

    return (
      <>
        <Button minimal icon={IconNames.USER} text={user.email} />
        <Navbar.Divider />
        <Button minimal icon={IconNames.LOG_OUT} onClick={event => this.handleSessionLogout(event)} />
      </>
    )
  }

  render() {
    return (
      <header className="Header" style={this.props.style}>
        <Consumer>
          {context => {
            this.context = context
            return (
              <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                  {this.renderMenu()}
                  <Navbar.Heading>
                    <AnchorButton minimal text="Grit" href="/" />
                  </Navbar.Heading>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                  {this.renderSession()}
                </Navbar.Group>
              </Navbar>
            )
          }}
        </Consumer>
      </header>
    )
  }
}

export default Header
