import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: {
        costBasis: 'Cost Basis',
        liabilities: 'Liabilities',
        netWorth: 'Net Worth',
        cash: {
          cost: 'Cost',
          costBasis: 'Cost Basis',
          liability: 'Liability',
          title: 'Cash',
          title_plural: 'Cash',
          value: 'Value'
        },
        property: {
          cost: 'Purchase Price',
          costBasis: 'Down Payment',
          liability: 'Mortgage',
          title: 'Real Estate',
          title_plural: 'Real Estate',
          value: 'Market Value'
        },
        stock: {
          cost: 'Cost',
          costBasis: 'Cost Basis',
          liability: 'Liability',
          title: 'Stock',
          title_plural: 'Stocks',
          value: 'Value'
        },
        vehicle: {
          cost: 'Purchase Price',
          costBasis: 'Down Payment',
          liability: 'Loan Amount',
          title: 'Vehicle',
          title_plural: 'Vehicles',
          value: 'Market Value'
        }
      }
    }
  },
  fallbackLng: 'en',
  debug: false,
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  },
  react: {
    wait: true
  }
})

export default i18n
