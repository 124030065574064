import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Intent,
  AnchorButton,
  FormGroup,
  InputGroup,
  Button,
  Classes,
  Checkbox
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { DatePicker } from '@blueprintjs/datetime'
import uuidv4 from 'uuid/v4'

import AppContext from '../AppContext'
import { Initial } from '../constants'
import { unformat, formatNumber } from '../formats'
import { hashList, classNames } from '../helpers'

import './style.scss'

class Form extends PureComponent {
  static contextType = AppContext
  static propTypes = {
    mode: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    parentItem: PropTypes.object.isRequired
  }
  static defaultProps = {
    mode: 'Add', // 'Edit'
    item: Initial.TRANSACTION,
    parentItem: Initial.ASSET
  }
  state = {
    transaction: this.props.item,
    asset: this.props.parentItem,
    isInterest: false
  }

  resetState() {
    this.setState({ transaction: Initial.TRANSACTION })
  }

  change(event, key, transform, callback) {
    const { target } = event
    let { value } = target

    if (transform) {
      value = transform(value)
    }

    this.setState(state => ({
      transaction: { ...state.transaction, [key]: value }
    }), callback)
  }

  handleAddTransactionSubmit(event) {
    event.preventDefault()
    const { parentItem } = this.props
    const { id, name } = parentItem
    const assetType = parentItem.type
    const { action, constants } = this.context
    const { ASSET_TYPES, CREDITS } = constants
    const { CASH } = ASSET_TYPES
    const {
      type,
      date,
      cost,
      value,
      units
    } = this.state.transaction
    const transaction = {
      id: uuidv4(),
      assetId: id,
      type,
      date,
      cost,
      value,
      units
    }
    const isNegative = CREDITS.includes(type)

    transaction.value = isNegative ? -value : value
    transaction.cost = isNegative ? -cost : cost

    switch (assetType) {
      case CASH:
        transaction.cost = this.state.isInterest 
          ? 0.0
          : transaction.value
        break
      default:
        break
    }

    action.add('transactions', transaction, () => {
      action.reset('dialog')
      action.addToast({
        icon: IconNames.TICK,
        intent: Intent.SUCCESS,
        message: `${type} transaction added to ${name}`
      })
      this.resetState()
    })
  }

  renderInputs() {
    const { constants } = this.context
    const { parentItem } = this.props
    const assetType = parentItem.type
    const { ASSET_TYPES } = constants
    const { CASH, STOCK } = ASSET_TYPES

    switch (assetType) {
      case CASH:
        return (
          <>
            {this.Interest}
            {!this.state.isInterest && (
              this.Type
            )}
            {this.Value}
          </>
        )
      case STOCK:
        return (
          <>
            {this.Cost}
            {this.Units}
          </>
        )
      default:
        return null
    }
  }

  get Type() {
    const { action, constants } = this.context
    const { TRANSACTION_TYPES } = constants

    return (
      <FormGroup
        label="Type"
        labelFor="type"
        labelInfo=""
        helperText=""
      >
        <div className={classNames(['bp3-select', 'bp3-large', 'bp3-minimal', 'bp3-fill'])}>
          <select
            id="type"
            onChange={event => this.change(event, 'type', false, () => {
              action.update('dialog', {
                children: <Form mode={this.props.mode} item={this.state.transaction} parentItem={this.props.parentItem} />
              })
            })}
            required
            defaultValue={this.props.item.type}
          >
            {hashList(TRANSACTION_TYPES).map((value, i) => (
              <option key={i} value={value}>{value}</option>
            ))}
          </select>
        </div>
      </FormGroup>
    )
  }

  get Interest() {
    return (
      <Checkbox
        checked={this.state.isInterest}
        label="Interest"
        onChange={() => this.setState(state => ({ isInterest: !state.isInterest }))}
      />
    )
  }

  get Date() {
    return (
      <FormGroup
        label="Date"
        labelFor="date"
        labelInfo=""
        helperText=""
      >
        <DatePicker
          onChange={date => this.setState(state => ({
            transaction: {
              ...state.transaction,
              date
            }
          }))}
          defaultValue={this.props.item.date}
        />
      </FormGroup>
    )
  }

  get Cost() {
    return (
      <FormGroup
        label="Cost"
        labelFor="cost"
        labelInfo=""
        helperText=""
      >
        <InputGroup
          id="cost"
          large
          type="text"
          placeholder="Cost"
          onChange={event => this.change(event, 'cost', unformat)}
          required
          leftIcon={IconNames.DOLLAR}
          defaultValue={formatNumber(this.props.item.cost)}
        />
      </FormGroup>
    )
  }

  get Value() {
    return (
      <FormGroup
        label="Value"
        labelFor="value"
        labelInfo=""
        helperText=""
      >
        <InputGroup
          id="value"
          large
          type="text"
          placeholder="Value"
          onChange={event => this.change(event, 'value', unformat)}
          required
          leftIcon={IconNames.DOLLAR}
          defaultValue={formatNumber(this.props.item.value)}
          onFocus={event => event.target.select()}
        />
      </FormGroup>
    )
  }

  get Units() {
    return (
      <FormGroup
        label="Units"
        labelFor="units"
        labelInfo=""
        helperText=""
      >
        <InputGroup
          id="units"
          large
          type="number"
          placeholder="Units"
          onChange={event => this.change(event, 'units', unformat)}
          required
          leftIcon={IconNames.NUMERICAL}
          defaultValue={formatNumber(this.props.item.units)}
        />
      </FormGroup>
    )
  }

  render() {
    const { action } = this.context
    const { mode } = this.props

    return (
      <form onSubmit={event => this[`handle${mode}TransactionSubmit`](event)}>
        <div className={classNames([Classes.DIALOG_BODY, 'flex'])}>
          <div className={classNames(['flex-0', 'half'])}>
            {this.Date}
          </div>
          <div className={classNames(['flex-0', 'half'])}>
            {this.renderInputs()}
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <AnchorButton
              text="Cancel"
              onClick={() => action.reset('dialog')}
            />
            <Button
              type="submit"
              text={mode}
              intent={Intent.PRIMARY}
            />
          </div>
        </div>
      </form>
    )
  }
}

export default Form
