import React, { PureComponent } from 'react'
import { Intent, AnchorButton, Switch } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { Initial } from '../constants'
import { hashList } from '../helpers'
import AppContext from '../AppContext'
import List from './List'
import AssetForm from './Form'
import './style.scss'

class Assets extends PureComponent {
  static contextType = AppContext
  state = {
    asset: Initial.ASSET,
    isDetailed: false
  }

  handleDetailChange() {
    this.setState(state => ({
      isDetailed: !state.isDetailed
    }))
  }

  handleAddAsset() {
    const { action, constants } = this.context
    const { ASSET_TYPES } = constants
    const types = hashList(ASSET_TYPES)

    this.setState(state => ({
      asset: {
        ...state.asset,
        type: state.asset.type || types[0]
      }
    }), () => {
      action.set('dialog', {
        isOpen: true,
        title: 'Add Asset',
        icon: IconNames.SERIES_ADD,
        onClose: () => action.reset('dialog'),
        children: <AssetForm mode="Add" item={this.state.asset} />
      })
    })
  }

  renderList() {
    const { assets } = this.context

    if (!assets || !assets.length) {
      return null
    }

    return (
      <main>
        <h1>Assets</h1>
        <Switch large checked={this.state.isDetailed} onChange={() => this.handleDetailChange()} />
        <List items={assets} isDetailed={this.state.isDetailed} />
      </main>
    )
  }

  render() {
    return (
      <div className="Container Assets">
        <header>
          <AnchorButton
            large
            text="Add Asset"
            icon={IconNames.SERIES_ADD}
            intent={Intent.PRIMARY}
            onClick={() => this.handleAddAsset()}
          />
        </header>
        {this.renderList()}
      </div>
    )
  }
}

export default Assets
