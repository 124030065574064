import {
  settings,
  formatMoney,
  formatNumber,
  unformat
} from 'accounting'

export const zero = '--'

settings.currency = {
  ...settings.currency,
  precision: 2,
  format: {
    ...settings.currency.format,
    pos: '%s %v',
    neg: '%s (%v)',
    zero: `%s ${zero}`
  }
}

settings.number = {
  ...settings.number,
  precision: 3
}

function date(value) {
  const options = {}

  if (!value) {
    return
  }

  const datetime = new Date(value)

  return datetime.toLocaleDateString('en-US', options)
}

export {
  formatMoney as $,
  formatNumber,
  unformat,
  date as d
}
