import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Content } from '../../Partials'
import './style.scss'

class Settings extends PureComponent {
  static propTypes = {
    style: PropTypes.object
  }

  render() {
    return (
      <Content className="Settings" style={this.props.style}>
        <h1>Settings</h1>
        <h2>Cash</h2>
        <p>Automatically calculate interest each month.</p>
      </Content>
    )
  }
}

export default Settings
