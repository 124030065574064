import React from 'react'
import page from 'page'

import client from './client'
import { Home, Dashboard, Portfolio, Settings, Equity } from './Containers'
import Login from './Sessions/Login'
import NotFound from './Errors/404'
import { Scroll } from './Partials/constants'

let app
let isUser
const props = {
  style: Scroll.DEFAULT
}

function login(_context, next) {
  isUser
    ? next()
    : page('/login')
}

function findById(context, next) {
  const { params } = context
  const { id } = params
  const { state } = app
  const items = state.assets
  const item = context.item = items.find(item => item.id === id)
  item
    ? next()
    : client(<NotFound {...props} {...context} />)
}

page('*', (context, next) => {
  app = client()
  const { state, action } = app
  const { user } = state
  const { canonicalPath, pathname } = context
  isUser = !!user

  action.add('history', { canonicalPath, pathname })

  next()
})

page('/login', () => {
  isUser
    ? page('/portfolio')
    : client(<Login {...props} />)
})

/**
 * Public
 */
page('/', () => {
  client(<Home {...props} />)
})
page('/portfolio', () => {
  client(<Portfolio {...props} />)
})
page('/equity/:id', findById, (context) => {
  const { item } = context

  client(<Equity item={item} {...props} />)
})

/**
 * Registered
 */
page('/dashboard', login, () => {
  client(<Dashboard {...props} />)
})
page('/settings', login, () => {
  client(<Settings {...props} />)
})

/**
 * Catch-all
 */
page('*', (context) => {
  client(<NotFound {...props} {...context} />)
})

page()
