import React, { PureComponent } from 'react'
import { I18nextProvider } from 'react-i18next'
import PropTypes from 'prop-types'
import { Toast, Toaster, Dialog, Alert } from '@blueprintjs/core'

import i18n from './i18n'
import Layout from './Layout'
import Main from './Main'
import { classNames, flattenByProp } from './helpers'
import AppContext, { Provider } from './AppContext'
import { Initial } from './constants'

import './App.scss'

class App extends PureComponent {
  static contextType = AppContext
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element
    ]),
    style: PropTypes.object
  }
  action = {
    // Object
    set: (key, payload, callback) => {
      this.setState(() => ({
        [key]: payload
      }), callback)
    },
    update: (key, payload, callback) => {
      this.setState(state => ({
        [key]: {
          ...state[key],
          ...payload
        }
      }), callback)
    },
    reset: (key, callback) => {
      this.setState(() => ({
        [key]: Initial[key.toUpperCase()]
      }), callback)
    },
    // Array
    add: (key, payload, callback) => {
      this.setState(state => ({
        [key]: state[key].concat(payload)
      }), callback)
    },
    insert: (key, payload, index, callback) => {
      this.setState(state => ({
        [key]: [
          ...state[key].slice(0, index),
          ...payload,
          ...state[key].slice(index)
        ]
      }), callback)
    },
    remove: (key, id, callback) => {
      this.setState(state => ({
        [key]: state[key].filter(item => item.id !== id)
      }), callback)
    },
    removeWhere: (key, prop, value, callback) => {
      this.setState(state => ({
        [key]: state[key].filter(item => item[prop] !== value)
      }), callback)
    },
    updateAt: (key, payload, index, callback) => {
      this.setState(state => ({
        [key]: state[key].map((item, i) => {
          if (i !== index) {
            return item
          }
          return {
            ...item,
            ...payload
          }
        })
      }), callback)
    },
    updateWhere: (key, prop, value, payload, callback) => {
      const index = this.state[key].findIndex(item => item[prop] === value)

      this.action.updateAt(key, payload, index, callback)
    },
    // Custom
    addToast: payload => {
      this.toaster.show(payload)
    },
    setStructure: (payload, callback) => {
      this.action.set('structure', payload, () => {
        this.action.set('layout', flattenByProp('children', payload), callback)
      })
    },
    get: key => key ? this.state[key] : this.state
  }
  state = {
    ...this.context,
    action: this.action
  }

  componentDidUpdate() {
    const { user, assets, transactions } = this.state

    localStorage.setItem('env', JSON.stringify({ user, assets, transactions }))
  }

  render() {
    const props = {
      extend: Main.name,
      style: {
        ...this.props.style
      }
    }
    return (
      <I18nextProvider i18n={i18n}>
        <div className={classNames(['App', 'bp3-dark'])}>
          <Provider value={this.state}>
            <Layout {...props}>
              {this.props.children}
            </Layout>
            <Toaster {...this.state.notification.toaster} ref={ref => this.toaster = ref}>
              {this.state.notification.toasts.map(toast => <Toast {...toast} />)}
            </Toaster>
            <Dialog className={classNames(['bp3-dark'])} {...this.state.dialog} />
            <Alert className={classNames(['bp3-dark'])} {...this.state.alert} />
          </Provider>
        </div>
      </I18nextProvider>
    )
  }
}

export default App
