import React from 'react'
import { render } from 'react-dom'

import App from './App'

const client = (components) => {
  const props = {}

  return render(
    <App {...props}>
      {components}
    </App>,
    document.getElementById('root')
  )
}

export default client
