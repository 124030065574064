import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Menu as List } from '@blueprintjs/core'

import { Consumer } from '../AppContext'
import './style.scss'

class Menu extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element
    ])
  }

  render() {
    return (
      <nav className="Menu" style={this.props.style}>
        <Consumer>
          {context => {
            const { menu, history } = context
            const { pathname } = history.slice(-1)[0] || {}
            const currentPathname = `/${pathname ? pathname.split('/')[1] : ''}`
            return (
              <List className="bp3-large">
                {menu.map(({ title, onClick, button, pathname, requiredContext }, i) => {
                  const isActive = pathname === currentPathname

                  if (!!requiredContext && !context[requiredContext]) {
                    return null
                  }

                  return (
                    <List.Item
                      key={i}
                      onClick={onClick}
                      text={title}
                      active={isActive}
                      {...button}
                    />
                  )
                })}
              </List>
            )
          }}
        </Consumer>
      </nav>
    )
  }
}

export default Menu
