import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import page from 'page'
import PropTypes from 'prop-types'
import {
  Button,
  Intent,
  Classes,
  AnchorButton,
  FormGroup,
  InputGroup
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import uuidv4 from 'uuid/v4'

import AppContext from '../AppContext'
import { Initial } from '../constants'
import { unformat, formatNumber } from '../formats'
import { hashList, classNames } from '../helpers'

import './style.scss'

class Form extends PureComponent {
  static contextType = AppContext
  static propTypes = {
    mode: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired
  }
  static defaultProps = {
    mode: 'Add', // 'Edit'
    item: Initial.ASSET
  }
  state = {
    asset: this.props.item
  }

  resetState() {
    this.setState({ asset: Initial.ASSET })
  }

  change(event, key, transform, callback) {
    const { target } = event
    let { value } = target

    if (transform) {
      value = transform(value)
    }

    this.setState(state => ({
      asset: { ...state.asset, [key]: value }
    }), callback)
  }

  handleAddAssetSubmit(event) {
    event.preventDefault()
    const { action } = this.context
    const {
      type,
      name,
      cost,
      value,
      units,
      percent,
      loan
    } = this.state.asset
    const asset = {
      id: uuidv4(),
      type,
      name,
      cost,
      value,
      units,
      percent,
      loan
    }

    action.add('assets', asset, () => {
      action.reset('dialog')
      action.addToast({
        icon: IconNames.TICK,
        intent: Intent.SUCCESS,
        message: `${name} added to ${type}`
      })
      this.resetState()
    })
  }

  handleEditAssetSubmit(event) {
    event.preventDefault()
    const { action } = this.context
    const {
      id,
      name,
      cost,
      value,
      units,
      percent,
      loan
    } = this.state.asset
    const asset = {
      name,
      cost,
      value,
      units,
      percent,
      loan
    }

    action.updateWhere('assets', 'id', id, asset, () => {
      action.reset('dialog')
      action.addToast({
        icon: IconNames.TICK,
        intent: Intent.SUCCESS,
        message: `${name} updated`
      })
      
      page.redirect(`/equity/${id}`)
    })
  }

  renderInputs() {
    const { constants } = this.context
    const { asset } = this.state
    const { type } = asset
    const { ASSET_TYPES } = constants
    const { CASH, STOCK, PROPERTY, VEHICLE } = ASSET_TYPES

    switch (type) {
      case CASH:
        return (
          <>
            {this.Percent}
          </>
        )
      case STOCK:
        return (
          <>
            {this.Units}
          </>
        )
      case PROPERTY:
      case VEHICLE:
        return (
          <>
            {this.Loan}
            {this.Percent}
          </>
        )
      default:
        return null
    }
  }

  get Type() {
    const { t } = this.props
    const { action, constants } = this.context
    const { ASSET_TYPES } = constants

    return (
      <FormGroup
        label="Type"
        labelFor="type"
        labelInfo=""
        helperText=""
      >
        <div className={classNames(['bp3-select', 'bp3-large', 'bp3-minimal', 'bp3-fill'])}>
          <select
            id="type"
            onChange={event => this.change(event, 'type', false, () => {
              action.update('dialog', {
                children: <Form {...this.props} mode={this.props.mode} item={this.state.asset} />
              })
            })}
            required
            defaultValue={this.props.item.type}
          >
            {hashList(ASSET_TYPES).map((value, i) => (
              <option key={i} value={value}>{t(value, { returnObjects: true }).title}</option>
            ))}
          </select>
        </div>
      </FormGroup>
    )
  }

  get Name() {
    return (
      <FormGroup
        label="Name"
        labelFor="name"
        labelInfo=""
        helperText=""

      >
        <InputGroup
          id="name"
          large
          placeholder="Name"
          onChange={event => this.change(event, 'name')}
          required
          defaultValue={this.props.item.name}
        />
      </FormGroup>
    )
  }

  get Cost() {
    return (
      <FormGroup
        label="Cost"
        labelFor="cost"
        labelInfo=""
        helperText=""
      >
        <InputGroup
          id="cost"
          large
          type="text"
          placeholder="Cost"
          onChange={event => this.change(event, 'cost', unformat)}
          required
          leftIcon={IconNames.DOLLAR}
          defaultValue={formatNumber(this.props.item.cost)}
        />
      </FormGroup>
    )
  }

  get Value() {
    return (
      <FormGroup
        label="Value"
        labelFor="value"
        labelInfo=""
        helperText=""
      >
        <InputGroup
          id="value"
          large
          type="text"
          placeholder="Value"
          onChange={event => this.change(event, 'value', unformat)}
          required
          leftIcon={IconNames.DOLLAR}
          defaultValue={formatNumber(this.props.item.value)}
        />
      </FormGroup>
    )
  }

  get Percent() {
    return (
      <FormGroup
        label="Percent"
        labelFor="percent"
        labelInfo=""
        helperText=""
      >
        <InputGroup
          id="percent"
          large
          type="number"
          placeholder="APY"
          step="0.01"
          onChange={event => this.change(event, 'percent', parseFloat)}
          required
          leftIcon={IconNames.PERCENTAGE}
          defaultValue={formatNumber(this.props.item.percent)}
        />
      </FormGroup>
    )
  }

  get Units() {
    return (
      <FormGroup
        label="Unit"
        labelFor="units"
        labelInfo=""
        helperText=""
      >
        <InputGroup
          id="units"
          large
          type="text"
          placeholder="Units"
          onChange={event => this.change(event, 'units', unformat)}
          required
          leftIcon={IconNames.NUMERICAL}
          defaultValue={formatNumber(this.props.item.units)}
        />
      </FormGroup>
    )
  }

  get Loan() {
    return (
      <FormGroup
        label="Loan"
        labelFor="loan"
        labelInfo=""
        helperText=""
      >
        <InputGroup
          id="loan"
          large
          type="text"
          placeholder="Loan"
          onChange={event => this.change(event, 'loan', unformat)}
          required
          leftIcon={IconNames.DOLLAR}
          defaultValue={formatNumber(this.props.item.loan)}
        />
      </FormGroup>
    )
  }

  render() {
    const { action } = this.context
    const { mode } = this.props
    const selectType = mode === 'Add' ? this.Type : null

    return (
      <form onSubmit={event => this[`handle${mode}AssetSubmit`](event)}>
        <div className={Classes.DIALOG_BODY}>
          {selectType}
          {this.Name}
          {this.Cost}
          {this.Value}
          {this.renderInputs()}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <AnchorButton
              text="Cancel"
              onClick={() => action.reset('dialog')}
            />
            <Button
              type="submit"
              text={mode}
              intent={Intent.PRIMARY}
            />
          </div>
        </div>
      </form>
    )
  }
}

export default withTranslation('translations')(Form)
