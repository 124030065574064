import React from 'react'

import Tag from './Tag'
import { Cushion } from './constants'

const Content = ({ children, style, ...rest }) => {
  const props = {
    style: {
      ...Cushion.DEFAULT,
      ...style
    },
    ...rest
  }
  return (
    <Tag {...props}>
      {children}
    </Tag>
  )
}

export default Content