import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Area, AreaChart, ResponsiveContainer } from 'recharts'
import { withTranslation } from 'react-i18next'

import AppContext from '../../AppContext'
import { $, formatNumber } from '../../formats'
import { Content, Change } from '../../Partials'
import { Cushion } from '../../Partials/constants'
import { figures } from '../../helpers'
import Assets from '../../Assets'
import './style.scss'

const data = [
  { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
  { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
  { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
  { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
  { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
  { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
  { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 }
]

class Portfolio extends PureComponent {
  static contextType = AppContext
  static propTypes = {
    style: PropTypes.object
  }

  render() {
    const { t } = this.props
    const { assets } = this.context
    const { total, change } = figures(assets)
    const areaProps = {
      isAnimationActive: false,
      type: 'monotone',
      stackId: '1',
      fill: 'transparent'
    }

    return (
      <Content
        className="Portfolio"
        style={{
          ...this.props.style,
          ...Cushion.FULL_WIDTH
        }}
      >
        <header>
          <h1>{t('netWorth')}</h1>
          <h2>{$(total.value - total.liability)}</h2>
          <h1>{t('costBasis')}</h1>
          <h2>{$(total.cost - total.liability)}</h2>
          <Change value={change.costValue.dollar} text={$(change.costValue.dollar)} />
          <Change value={change.costValue.percent} text={`${formatNumber(change.costValue.percent)} %`} />
          <div>
            <h1>{t('liabilities')}</h1>
            <h2>{$(total.liability)}</h2>
          </div>
        </header>
        <main>
          <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>
            <AreaChart data={data}>
              <Area {...areaProps} dataKey='uv' stroke='#8884d8' />
              <Area {...areaProps} dataKey='pv' stroke='#82ca9d' />
              <Area {...areaProps} dataKey='amt' stroke='#ffc658' />
            </AreaChart>
          </ResponsiveContainer>
          <Assets />
        </main>
      </Content>
    )
  }
}

export default withTranslation('translations')(Portfolio)
