export const Scroll = Object.freeze({
  DEFAULT: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'auto'
  }
})

export const Cushion = Object.freeze({
  DEFAULT: {
    padding: '2.0rem'
  },
  FULL_WIDTH: {
    paddingLeft: 0,
    paddingRight: 0
  }
})